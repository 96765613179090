var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"RelatedArticles section-content"},[_c('div',{staticClass:"section-content"},[_c('h2',{staticClass:"post-title text-uppercase hidden"},[_vm._v(" "+_vm._s(_vm.$t('articles similaires'))+" : ")]),_vm._v(" "),_c('client-only',[_c('div',{staticClass:"owl-carousel_ blog-related-slider_ owl-btn-top-right_ row"},_vm._l((_vm.data),function(value,index){return _c('div',{key:index,staticClass:"_item col-lg-6 col-md-6 col-sm-12"},[_c('div',{staticClass:"blog-post blog-grid date-style-1"},[_c('div',{staticClass:"wt-post-media wt-img-effect zoom-slow lz-loading ratio-container unknown-ratio-container"},[_c('nuxt-link',{attrs:{"to":_vm.getLocalizedRoute({
                            name: 'news-category-slug'
                            , params: {
                                category: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, value.category.slug, 'tag')
                                , slug: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, value.slug, 'article')
                            }
                                })}},[_c('img',{staticClass:"img-responsive img-responsive-vertical mobile lazyload",attrs:{"width":_vm.$getImageSizeByFilterSets('width', 'grid_nostamp'),"height":_vm.$getImageSizeByFilterSets('height','grid_nostamp'),"data-src":_vm.imageMobilePath(value.primaryImage),"alt":value.primaryImage.alt}}),_vm._v(" "),_c('img',{staticClass:"img-responsive img-responsive-horizontal desktop lazyload",attrs:{"width":_vm.$getImageSizeByFilterSets('width', 'grid'),"height":_vm.$getImageSizeByFilterSets('height','grid'),"data-src":_vm.imagePath(value.primaryImage),"alt":value.primaryImage.alt}})])],1),_vm._v(" "),_c('div',{staticClass:"wt-post-info p-a20 bg-gray text-black"},[_c('div',{staticClass:"wt-post-title"},[_c('h4',{staticClass:"post-title"},[_c('nuxt-link',{staticClass:"text-black font-16 letter-spacing-2 font-weight-600",attrs:{"to":_vm.getLocalizedRoute({
                                name: 'news-category-slug'
                                , params: {
                                    category: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, value.category.slug, 'tag')
                                    , slug: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, value.slug, 'article')
                                }
                                    })}},[_vm._v("\n                                    "+_vm._s(value.alternativeHeadline)+"\n                                ")])],1)]),_vm._v(" "),_c('div',{staticClass:"wt-post-meta"}),_vm._v(" "),_c('div',{staticClass:"wt-post-text"},[_c('p',[_c('nuxt-link',{attrs:{"to":_vm.getLocalizedRoute({
                                name: 'news-category-slug'
                                , params: {
                                    category: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, value.category.slug, 'tag')
                                    , slug: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, value.slug, 'article')
                                }
                                    })}},[_vm._v("\n                                "+_vm._s(_vm._f("truncate")(value.articleResume,_vm.params.truncate))+"\n                                ")])],1)]),_vm._v(" "),_c('nuxt-link',{staticClass:"site-button black radius-no text-uppercase",attrs:{"to":_vm.getLocalizedRoute({
                                name: 'news-category-slug'
                                , params: {
                                    category: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, value.category.slug, 'tag')
                                    , slug: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, value.slug, 'article')
                                }
                                    })}},[_c('span',{staticClass:"font-12 letter-spacing-5"},[_vm._v(" "+_vm._s(_vm.$t('plus d\'infos'))+" ")])])],1)])])}),0)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }